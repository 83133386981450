import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { useFirestore } from '../../firebase/useFirestore';
import PortfolioMeta from '../../components/syncronisations/PortfolioMeta';
import PortfolioOutputs from '../../components/syncronisations/PortfolioOutputs';
import PortfolioEffects from '../../components/syncronisations/PortfolioEffects';
import useSettings from "../../hooks/Settings";
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Synchronisations = () => {
  // State
  const [outputTitle, setOutputTitle] = useState('');
  const [effectTitle, setEffectTitle] = useState('');

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Firestore
    const syncs = useFirestore('Synchronisations')

    console.log(syncs)

    // Get the output title from the portfolio database
    useEffect(() => {
      const fetchOutputTitle = async () => {
          if ( syncs && syncs[0]?.Type === 'output') {
              try {
                  const title = await PortfolioOutputs(syncs[0].PortfolioID, syncs[0].SyncItem);
                  setOutputTitle(title);
              } catch (error) {
                  console.error('Error fetching portfolio output:', error);
                  setOutputTitle('Error fetching output'); // Fallback message
              }
          }
      };

      fetchOutputTitle();
  }, [syncs]);

  // Get the effect title from the portfolio database
  useEffect(() => {
      const fetchEffectTitle = async () => {
          if ( syncs && syncs[0]?.Type === 'effect') {
              try {
                  const title = await PortfolioEffects(syncs[0].PortfolioID, syncs[0].SyncItem);

                  setEffectTitle(title);
              } catch (error) {
                  console.error('Error fetching portfolio effect:', error);
                  setEffectTitle('Error fetching effect'); // Fallback message
              }
          }
      };
  
      fetchEffectTitle();
  }, [syncs]);

    // adjust the name and color of the status
    const status = (statusCode) => {
      switch (statusCode) {
        case 'requested':
          return { text: 'Verzoek', color: '#FFA500' }; // Orange
        case 'accepted':
          return { text: 'Geaccepteerd', color: '#008000' }; // Green
        case 'declined':
          return { text: 'Geweigerd', color: '#FF0000' }; // Red
        case 'deleted':
          return { text: 'Verwijderd', color: '#000000' }; // Red
        default:
          return { text: 'Onbekende status', color: '#000000' }; // Black
      }
    }

    // Data sync status
    const syncStatus = (statusCode) => {
      switch (statusCode) {
        case 'requested':
          return { text: 'Inactief', color: '#FFA500' }; // Orange
        case 'accepted':
          return { text: 'Actief', color: '#008000' }; // Green
        case 'declined':
          return { text: 'Inactief', color: '#FF0000' }; // Red
        case 'deleted':
          return { text: 'Inactief', color: '#FF0000' }; // Black
        default:
          return { text: 'Onbekende status', color: '#000000' }; // Black
      }
    }
  
    // Adjust the name of the type
    const type = (type) => {
      switch (type) {
        case 'output':
          return 'Output';
        case 'effect':
          return 'Effect';
        default:
          return 'Onbekend';
      }
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <SyncOutlinedIcon />
                <h1>Synchronisations</h1>
            </div>
            <div className='dashboard-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>ORGANIATIE</th>
                        <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                        <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                        <th style={{backgroundColor: secundairyColor}}>STATUS</th>
                        <th style={{backgroundColor: secundairyColor}}>SYNCHRONISATIE</th>
                        <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                    </tr>
                    {syncs && syncs.map((sync) => (
                        <tr key={sync.ID}>
                            <td>
                                <p><PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs}/></p>
                            </td>
                            <td>
                                <p>{type(sync.Type)}</p>
                            </td>
                            <td>
                                {sync.Type === 'effect' && <p>{effectTitle}</p>}
                                {sync.Type === 'output' && <p>{outputTitle}</p>}
                            </td>
                            <td>
                                <p style={{color: status(sync.Status).color}}>{status(sync.Status).text}</p>
                            </td>
                            <td>
                                <p >{syncStatus(sync.Status).text}</p>
                            </td>
                            <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/synchronisationbuilder/${sync.ID}`)} alt="" />
                            </td>
                        </tr> 
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default Synchronisations