import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";

const MatrixPrint = ({field}) => {

    const matrixRows = useFirestoreGeneralOrderBy('MatrixQuestionRows', 'Field', field.ID, 'Position', 'asc')
    const matrixColumns = useFirestoreGeneralOrderBy('MatrixQuestionColumns', 'Field', field.ID, 'Position', 'asc')

  return (
    <div id='matrix-grid-container'>
            
                {/* Matrix column titles */}
                <div className='matrix-grid'>
                    {/* Placeholder for empty top-left cell */}
                    <div className='matrix-grid-header'></div>
                    {matrixColumns && matrixColumns.map((column) => (
                    <div key={column.ID} className='matrix-grid-header'>
                        <p>{column.Title}</p>
                    </div>
                    ))}
                </div>

                {/* Matrix rows with radio buttons */}
                {matrixRows && matrixRows.map(row => (
                    <div key={row.ID} className='matrix-grid-row' style={{ display: 'contents' }}>
                    {/* Row title with flex-start alignment */}
                    <div className='matrix-grid-header first-column'> {/* Add the "first-column" class */}
                        <p>{row.Title}</p>
                    </div>
                    
                    {/* Row radio buttons */}
                    {matrixColumns && matrixColumns.map(column => (
                        <div key={column.ID} className='matrix-grid-item'>
                        <input 
                            className='question-radio-input' 
                            type="radio" 
                            name={`row-${row.ID}`}  // Each row has the same name for radio buttons
                            id={column.ID} 
                            data-id={field.ID} 
                            data-itemid={column.ID}
                            data-rowtitle={row.Title}
                            value={column.Title}
                            data-columnid={column.ID}
                            data-fieldtype={field.Type}
                        />
                        </div>
                    ))}
                    </div>
                ))}
            </div>

  )
}

export default MatrixPrint