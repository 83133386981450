import { useState, useEffect } from 'react'
import { portfolioFunctions } from '../../firebase/configPortfolio'
import spinner from '../../images/spinner-ripple.svg'

const PortfolioEffectIndicators = ({portfolioID, syncs}) => {
// State
const [indicators, setIndicators] = useState([])
const [loading, setLoading] = useState(true)

 // Get portfolio meta from API
 useEffect(() => {
    const getPortfolioName = async () => {

      const portfolioMeta = portfolioFunctions.httpsCallable('portfolioEffectIndicators');

      portfolioMeta({ data: portfolioID })
      .then((result) => {
        setLoading(false)
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
        alert(`Er is iets mis gegaan, neem contact op met Deccos`)
      });
    }

    getPortfolioName()
}, [syncs])

  return (
    <>{loading ? <img src={spinner}/> : indicators}</>
  )
}

export default PortfolioEffectIndicators