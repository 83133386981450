import printIcon from "../../images/icons/print-icon.png"
import arrowLeftIcon from "../../images/icons/arrow-left-icon.png"
import { useHistory } from "react-router-dom";
import { useFirestoreID, useFirestoreGeneralOrderBy } from '../../firebase/useFirestore';
import Location from '../../hooks/Location';
import { useEffect, useState } from "react";
import MultipleOptions from '../../components/Questionnaire/MultipleOptions';
import MatrixPrint from "../../components/Questionnaire/MatrixPrint";

const PrintQuestionaire = () => {
    // State
    const [questionnaire, setQuestionnaire] = useState('')
    const [client, setClient] = useState('')
    const [measureMomentID, setMeasureMomentID] = useState('')
    const [researchID, setResearchID] = useState('')
    const [persona, setPersona] = useState('')

    // Hooks
    const history = useHistory()

    // Url parameters
    const clientUrl = Location()[1]
    const questionnaireUrl = Location()[3]
    const measureMomentIDUrl = Location()[4]
    const researchIDUrl = Location()[5]
    const personaUrl = Location()[6]

    // Firestore
    const questionnares = useFirestoreID('Questionnaires', questionnaire ? questionnaire : '123')
    const fields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionnaire ? questionnaire : '123', 'Position', 'asc')

    useEffect(() => {
        setClient(clientUrl)
        setQuestionnaire(questionnaireUrl)
        setMeasureMomentID(measureMomentIDUrl)
        setResearchID(researchIDUrl)
        setPersona(personaUrl)
    }, [clientUrl, questionnaireUrl, measureMomentIDUrl, researchIDUrl, personaUrl])

    const rangeFinder = (field) => {
        const start = field.ReachStart
        const end = field.ReachEnd

        const range = (start, end) => {
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        }

        const result = range(start, end)
          
        return result

    }

  return (
    <div className="main">
        <div className="main-container">
            <div className="print-go-back-container" onClick={() => history.push(`/${client}/sharequestionnaire/${questionnaire}/${researchID}/${persona}`)}>
                <img src={arrowLeftIcon}/>
                <p>Terug</p>
            </div>
            <div className="print-icon-container" onClick={() => window.print()}>
                <img src={printIcon} alt="print icon" className="print-icon" />
                <p>Print vragenlijst</p>
            </div>
            <div id='print-questionniare-container'>
                {questionnares && questionnares.map(questionnaire => (
                    <div key={questionnaire.ID} className="questionnaire-container">
                        <h2>{questionnaire.Title}</h2>
                        <p>{questionnaire.Description}</p>
                    </div>
                ))}   
                <div id="print-questionnaire-fields-container">
                    {fields && fields.map((field, index) => (
                        <div key={field.ID} className="questionnaire-field-container questionaire-field-print-container">
                            {field.Type !== 'section' && 
                                <div>
                                    <h3>{field.Question}</h3>
                                    <p>{field.Explainer}</p>

                                    {/* Paragraph */}
                                    {field.Type === 'paragraph' &&
                                        <div className='questionnaire-field-type-paragraph-container print-questionaire-paragraph-container' style={{display: field.Type === 'paragraph' ? 'block' : 'none'}}>
                                            <p className='questionnaire-field-type-text' data-index={index}>Antwoord</p>
                                        </div>
                                    }

                                    {/* Scale */}
                                    {field.Type === 'scale' &&
                                        <div id='scale-container' style={{display: field.Type === 'scale' ? 'flex' : 'none'}}>
                                            {field.ReachStartLable}
                                            {rangeFinder(field).map(btn => (
                                            <div id='question-type-label-container' data-index={index}>
                                                    <input type="radio" value={btn} />
                                                    <label htmlFor={btn}>{btn}</label>
                                            </div>
                                            ))}
                                            {field.ReachEndLabel}
                                        </div>
                }

                                    {/* Multiple choice */}
                                    {(field.Type === 'multiple-one' || field.Type === 'multiple-multiple') &&
                                     <MultipleOptions fieldID={field.ID}/>
                                    }

                                    {/* Matrix */}
                                    {(field.Type === 'matrix-one' || field.Type === 'matrix-multiple') &&
                                        <MatrixPrint field={field}/>
                                    }

                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default PrintQuestionaire